<template>
<Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
    <div class="height-div">
        <transition name="slide-fade">
          <div id="aliados" class="flex-item-row offset pt-16 pb-16" v-if="animation1"> 
              <p class="title-2"> Nuestros Aliados</p>
              <p class="subtitle-6">Juntos somos <span style="color:#00C55B">más fuertes</span> </p>

            <picture >
                  <source media="(max-width: 700px)" srcset="../assets/animacion/07/Yipi_AnimacionWeb_07_Ipad.gif">
                  <img loading="lazy" class="video-animation" src="../assets/animacion/07/Yipi_AnimacionWeb_07.gif" alt="animation yipi">
            </picture>
          </div>
        </transition>
    </div>
</Intersect>
</template>
<style >
   @import '../styles/aliados.css';
</style>
<script>
  import Intersect from 'vue-intersect'
export default {
  components:{
    Intersect
  },
  data:()=>({
    animation1:false
  })
}
</script>